import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/13/1.gif'
import img1 from '../../assets/blog/13/2.png'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Pět důvodů, proč se vlastně netěšíte na dovolenou" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Pět důvodů, proč se vlastně netěšíte na dovolenou" />
        <ContentSubPage>
          <p>
            V práci plná hlava starostí, výčitky, že netrávím dost času s
            rodinou, žádný volný čas na čtení a sport. To všechno vyřeší krásná
            dovolená zalitá sluncem, pobyt mimo všední realitu. Tak to vidíme v
            zimě a ještě i na jaře. Tak proč se s blížícím se termínem odjezdu
            tato představa ráje postupně mění? Proč vlastně v souvislosti s
            dovolenou zažíváme stres?
          </p>
          <p>
            <b>Výběr destinace.</b> Výzkumy ukazují, že většina lidí zažívá
            největší úzkost, ještě než dovolená vůbec začne. Samotný výběr
            destinace není nic lehkého a svou roli mohou sehrát finanční limity.
            Řešením je stanovit si rozpočet a cíl cesty vybrat s ohledem na něj.
            Je přeci tolik skvělých míst, která lze poznávat. Ty
            nejnavštěvovanější destinace navíc už často nenabízejí autentický
            prožitek, nebo vás tam místní stejně moc rádi nevidí.
          </p>
          <p>
            <b>Plánování.</b> Oříšek i pro zkušené cestovatele. Kde bydlet? Jak
            se tam dostat? Kde zaparkovat u letiště? Jak se sbalit do letadla?
            Jaké památky chci a stihnu vidět? Kam je třeba koupit vstupenku s
            předstihem? Tahle fáze je skutečně náročná a zároveň se vyplatí jí
            věnovat nějaký ten čas a pozornost, protože s ní může stát a padat
            úspěch dovolené. Naštěstí máme internet, recenze a taky aplikace,
            které nám pomohou mít všechny informace na jednom místě.
          </p>
          <p>
            <b>Balení.</b> Pokud balíte jen sebe, nejde o příliš náročný úkol.
            Navíc, zapomenutý kartáček si asi snadno dokupíte kdekoliv na světě.
            Pokud se ale staráte o komfort celé rodiny, je dobré začít s balením
            pár dní před odjezdem a do seznamu zaznamenávat, co je ještě potřeba
            nachystat, vyprat, případně dokoupit.
          </p>
          <p>
            <b>Cesta.</b> Jak ji zvládneme? Potřebuje někdo z cestující
            společnosti léky proti nevolnosti? Balit s sebou jídlo? Hračky?
            Jakou časovou rezervu zvolit? Jak se dopravíme na ubytování? Přitom
            stačí málo. Zamyslet se, stáhnout si mapy, dopravní spojení a přidat
            pár položek na seznam věcí ke sbalení.
          </p>
          <p>
            <b>Pobyt v neznámém prostředí</b> stresuje některé typy lidí víc než
            jiné. Nebuďte na sebe přísní, nechte si na aklimatizaci pár dní a
            udržujte si otevřenou mysl. A jazyková bariéra? Tam, kde si
            nevystačíte se základy angličtiny, pomohou praktické online
            překladače.
          </p>
          <p>
            S komplexní a adaptabilní aplikací, jako je například Flagis, lze
            výraznou část starostí spojených s dovolenou zredukovat. Pozitiva
            cestování potom převáží a dovolená nám poslouží přesně tak, jak má.
            To znamená, že nám přinese odpočinek, pauzu od pracovních
            povinností, zlepšení tělesné kondice a pocit, že máme život pod
            kontrolou.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
